import React from "react";

function Sector() {
  document.title = 'LEGUM INN-Sector';
  return (
    <div>
      <section className="about-us section">
        <div className="container py-5">
          <div className="inner">
            <h4 style={{ textAlign: "center" }}>E-commerce</h4>
            <p>
              The e-commerce has transformed the way business is done in India.
              The Indian e-commerce industry has been on an upward growth
              trajectory and is expected to grow at a Compound Annual Growth
              Rate (CAGR) of 28 per cent from 2016-20 to touch US$ 63.7 billion
              by 2020 and overtake the US by 2034.1 The sector reached US$ 14.5
              billion in 2016.
            </p>
            <p>
              Much growth of the industry has been triggered by increasing
              internet and smartphone penetration. The ongoing digital
              transformation in the country is expected to increase India’s
              total internet user base to 829 million by 2021 (59 per cent of
              total population), from 373 million (28 per cent of population) in
              2016, while total number of networked devices in the country are
              expected to grow to two billion by 2021, from 1.4 billion in 2016.
            </p>
            <h6>MARKET SIZE</h6>
            <p>
              Total online spending, inclusive of domestic and cross border
              shopping, is expected to increase by 31 per cent year-on-year to
              Rs 8.76 trillion (US$ 135.8 billion) by 2018. Cross border
              shopping by Indians touched Rs 58,370 crore (US$ 9.1 billion) in
              2016, and is expected to by 85 per cent year-on-year in 2017. The
              top 3 countries preferred by Indians for cross-border shopping in
              2016 were USA (14%), UK (6%) and China (5%).
            </p>
            <p>
              The Indian consumer internet market is expected to grow by 44 per
              cent year-on-year to touch US$65 billion in 2017, up from US$45
              billion in 2016. Online travel agents account for the largest
              market share (70 per cent) in the internet consumer market, while
              the remaining 30 per cent is occupied by horizontal e-tailing,
              fashion, furniture, grocery, hotel, food tech, cab aggregators,
              education technology, and alternative lending among others.
            </p>
            <p>
              {" "}
              The internet industry in India is likely to double to reach US$
              250 billion by 2020, growing to 7.5 per cent of Gross Domestic
              Product (GDP), with the number of mobile internet users growing to
              about 650 million and that of high-speed internet users reaching
              550 million.5 About 70 per cent of the total automobile sales in
              India, worth US$ 40 billion, are expected to be digitally
              influenced by 2020 as against US$ 18 billion in 2016.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Sector;
