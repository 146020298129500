import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import AboutUs from "./components/AboutUs";
import CorePractice from "./components/CorePractice";
import AllServices from './components/AllServices';
import Service from "./components/Service";
import Teams from "./components/Teams";
import Career from "./components/Career";
import AllSectors from "./components/AllSectors";
import Sector from "./components/Sector";
import Blogs from "./components/Blogs";
import OurImageGallery from "./components/OurImageGallery";
import OurVideoGallery from "./components/OurVideoGallery";

function App(){

  return (
    <Router>
      <Navbar />
      <div style={{paddingTop:"80px"}}>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/aboutus" element={<AboutUs />} />
        <Route exact path="/corepractice" element={<CorePractice />} />
        <Route exact path="/allsectors" element={<AllSectors />} />
        <Route exact path="/sector" element={<Sector />} />
        <Route exact path="/allservices" element={<AllServices />} />
        <Route exact path="/service" element={<Service />} />
        <Route exact path="/blogs" element={<Blogs/>} />
        <Route exact path="/imagegallery" element={<OurImageGallery/>} />
        <Route exact path="/videogallery" element={<OurVideoGallery/>} />
        <Route exact path="/teams" element={<Teams />} />
        <Route exact path="/career" element={<Career />} />
        

      </Routes>
      <Footer />
      </div>
      
    </Router>
  );
}

export default App;
