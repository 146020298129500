import React, { Component } from "react";

export class Contact extends Component {
  render() {
    document.title = "LEGUM INN-ContactUs";
    return (
      <div>
        <section className="contactus">
          <div className="bg_image_1">
            <div className="content">
              <h3>Contact Us</h3>
            </div>
          </div>
        </section>
        <section className="contact-us section">
          <div className="container py-5">
            <div className="inner">
              <div className="row">
                <div className="col-lg-6">
                  <div className="contact-us-left">
                    <div className="contact-info fs-3">
                      <i className="bx bx-home"></i>
                      <span className="contact-label">Head Office</span>
                      <p className="my-3">
                        10, First Floor, CSC, New Friends Colony, South
                        East Delhi-110065
                      </p>
                    </div>
                    <div className="contact-info fs-3">
                      <i className="bx bx-envelope"></i>
                      <span className="contact-label">Email Address</span>
                      <p>leguminn@gmail.com</p>
                    </div>
                    <div className="contact-info fs-3">
                      <i className="bx bx-phone"></i>
                      <span className="contact-label">Telephone</span>
                      <p>+91 786 0786 427</p>
                      {/* <p>+91 1144725519</p> */}
                    </div>
                    <div className="contact-info fs-3">
                      <i className="bx bx-time"></i>
                      <span className="contact-label">Office Hour</span>
                      <p>Mon-Sat: 10am – 7pm</p>
                    </div>
                  </div>

                  {/* <div className="contact-us-left">
							<i className='bx bx-home'></i><span>Head Office</span><p>Legum Inn, Near Khalilullah Masjid,Batla House<br/>Jamia Nagar,New Delhi-110025</p>
							<i className="icofont-email"><span>Email Address</span><p>office@leguminn.in</p></i>
							<i className='bx bx-phone'><span>Telephone</span><p>(011) 12345678 / 87654321</p></i><br/>
							<i className="bx bx-time"><span>Office Hour</span><p>Mon-Sat: 10am – 7pm</p></i>
							</div> */}
                </div>
                <div className="col-lg-6">
                  <div className="contact-us-form">
                    <h2>Contact With Us</h2>
                    <p>
                      If you have any questions please fell free to contact with
                      us.
                    </p>
                    {/* <!-- Form --> */}
                    <form className="form" method="post" action="mail/mail.php">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="name"
                              placeholder="Name"
                              required=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              placeholder="Email"
                              required=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="phone"
                              placeholder="Phone"
                              required=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="subject"
                              placeholder="Subject"
                              required=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <textarea
                              name="message"
                              placeholder="Your Message"
                              required=""
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group login-btn">
                            <button
                              className="btn"
                              style={{ backgroundColor: "#D4AC2B" }}
                              type="submit"
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Contact;
