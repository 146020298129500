import React from "react";
import blog1 from "../image/blog1.jpg";
import blog2 from "../image/blog2.jpg";
import blog3 from "../image/blog3.jpg";

function Blogs() {
  document.title = "LEGUM INN-MediaLinks";
  return (
    <div>
      <section className="medialinks">
        <div className="bg_image_1">
          <div className="content">
            <h3>Media & Links</h3>
          </div>
        </div>
      </section>
      <section className="blog section" id="blog">
        <div class="container py-5">
          <div class="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-3 g-lg-3">
            <div class="col">
              <div className="single-news">
                <div className="news-head">
                  <img src={blog2} alt="#" />
                </div>
                <div className="news-body">
                  <div className="news-content">
                    <div className="date">15 Jul, 2020</div>
                    <h2>
                      <a href="blog-single.html">
                        The Impact of Social Media to the Society
                      </a>
                    </h2>
                    <p className="text">
                    When asked whether social media is a good or bad thing for
                      democracy in their country, a median of 57% across 19
                      countries say that it is a good thing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div className="single-news">
                <div className="news-head">
                  <img src={blog3} alt="#" />
                </div>
                <div className="news-body">
                  <div className="news-content">
                    <div className="date">05 Jan, 2020</div>
                    <h2>
                      <a href="blog-single.html">
                        Estate Planing for Small Business & Articles
                      </a>
                    </h2>
                    <p className="text">
                    It becomes difficult for the rest of the employees to manage
                      and continue commercial activities in the proper order.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div className="single-news">
                <div className="news-head">
                  <img src={blog1} alt="#" />
                </div>
                <div className="news-body">
                  <div className="news-content">
                    <div className="date">22 Aug, 2020</div>
                    <h2>
                      <a href="blog-single.html">
                        Explore The Importance Of Having A Will
                      </a>
                    </h2>
                    <p className="text">
                    A common excuse we’ve heard from those who have yet to
                      create their will is “my family will know what to do if I
                      die.”
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div className="single-news">
                <div className="news-head">
                  <img src={blog1} alt="#" />
                </div>
                <div className="news-body">
                  <div className="news-content">
                    <div className="date">22 Aug, 2020</div>
                    <h2>
                      <a href="blog-single.html">
                        Explore The Importance Of Having A Will
                      </a>
                    </h2>
                    <p className="text">
                    A common excuse we’ve heard from those who have yet to
                      create their will is “my family will know what to do if I
                      die.”
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div className="single-news">
                <div className="news-head">
                  <img src={blog3} alt="#" />
                </div>
                <div className="news-body">
                  <div className="news-content">
                    <div className="date">05 Jan, 2020</div>
                    <h2>
                      <a href="blog-single.html">
                        Estate Planing for Small Business & Articles
                      </a>
                    </h2>
                    <p className="text">
                    It becomes difficult for the rest of the employees to manage
                      and continue commercial activities in the proper order.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div className="single-news">
                <div className="news-head">
                  <img src={blog2} alt="#" />
                </div>
                <div className="news-body">
                  <div className="news-content">
                    <div className="date">15 Jul, 2020</div>
                    <h2>
                      <a href="blog-single.html">
                        The Impact of Social Media to the Society
                      </a>
                    </h2>
                    <p className="text">
                    When asked whether social media is a good or bad thing for
                      democracy in their country, a median of 57% across 19
                      countries say that it is a good thing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Blogs;
