import React from 'react'
import VideoPlayerModal from './VideoPlayerModal'
import '../styles.css';

function OurVideoGallery() {
    const videoData = [{
        poster: "https://images.pexels.com/photos/5669619/pexels-photo-5669619.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        video: "https://player.vimeo.com/external/507872134.sd.mp4?s=ed1f1f0c7ad50ead382fb1441631c27a30c8fb1c&profile_id=164&oauth2_token_id=57447761#t=0.1",
      },{
        poster: "https://images.pexels.com/photos/6077447/pexels-photo-6077447.jpeg?auto=compress&cs=tinysrgb&w=600",
        video: "https://player.vimeo.com/external/574377060.sd.mp4?s=494d6f06b9be04338f24da90b4e80d05115beb69&profile_id=164&oauth2_token_id=57447761",
      },
      {
        poster: "https://images.pexels.com/photos/5669619/pexels-photo-5669619.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        video: "https://player.vimeo.com/external/507872134.sd.mp4?s=ed1f1f0c7ad50ead382fb1441631c27a30c8fb1c&profile_id=164&oauth2_token_id=57447761",
      },
      {
        poster: "https://images.pexels.com/photos/6077447/pexels-photo-6077447.jpeg?auto=compress&cs=tinysrgb&w=600",
        video: "https://player.vimeo.com/external/574377060.sd.mp4?s=494d6f06b9be04338f24da90b4e80d05115beb69&profile_id=164&oauth2_token_id=57447761",
      },
    ];
  return (
    <section className="video-gallery">
        <div className="bg_image_1">
          <div className="content">
            <h3>Video Gallery</h3>
          </div>
        </div>
        <div className='py-5'>
        <VideoPlayerModal videoData={videoData} />
    </div>
      </section>
    
  )
}

export default OurVideoGallery
