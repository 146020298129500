import React, { useState } from "react";
import DisclaimerModal from "./DisclaimerModal";
import { Link } from "react-router-dom";
import slider1 from "../image/slider.jpg";
import slider2 from "../image/slider1.jpg";
import slider3 from "../image/slider2.jpg";
import blog1 from "../image/blog1.jpg";
import blog2 from "../image/blog2.jpg";
import blog3 from "../image/blog3.jpg";
import contactimg from "../image/contact-img.png";

function Home() {
  document.title = "LEGUM INN-Home";
  const [showModal, setShowModal] = useState(
    !localStorage.getItem("disclaimerAgreed")
  );

  const handleAgree = () => {
    localStorage.setItem("disclaimerAgreed", "true");
    setShowModal(false);
  };

  const handleDisagree = () => {
    window.history.back();
    setShowModal(false);
  };
  return (
    <div>
      <div
        id="carouselExampleCaptions"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src={slider3}
              className="d-block w-100 img-fluid"
              alt="..."
              style={{ maxHeight: "600px", width: "100%", objectFit: "cover" }}
            />
            <div
              className="carousel-caption top-0"
              style={{ marginRight: "30%", marginTop: "10%" }}
            >
              <h1
                className="animated bounceInRight"
                style={{ animationDelay: ".2s" }}
              >
                Offering the Highest <span>Quality</span> Legal Services That
                You Can <span>Trust!</span>
              </h1>
              <p
                className="animated bounceInLeft"
                style={{ animationDelay: ".3s" }}
              >
                <Link className="btn" to="/contact">
                  ContactS
                </Link>
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={slider2}
              className="d-block w-100 img-fluid"
              alt="..."
              style={{ maxHeight: "600px", width: "100%", objectFit: "cover" }}
            />
            <div
              className="carousel-caption top-0"
              style={{ marginRight: "30%", marginTop: "10%" }}
            >
              <h1
                className="animated bounceInRight"
                style={{ animationDelay: ".2s", color: "#fff" }}
              >
                Offering the Highest <span>Quality</span> Legal Services That
                You Can <span>Trust!</span>
              </h1>
              <p
                className="animated bounceInLeft"
                style={{ animationDelay: ".3s" }}
              >
                <Link className="btn" to="/contact">
                  ContactS
                </Link>
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={slider1}
              className="d-block w-100 img-fluid"
              alt="..."
              style={{ maxHeight: "600px", width: "100%", objectFit: "cover" }}
            />
            <div
              className="carousel-caption top-0"
              style={{ marginRight: "30%", marginTop: "10%" }}
            >
              <h1
                className="animated bounceInRight"
                style={{ animationDelay: ".2s" }}
              >
                Offering the Highest <span>Quality</span> Legal Services That
                You Can <span>Trust!</span>
              </h1>
              <p
                className="animated bounceInLeft"
                style={{ animationDelay: ".3s" }}
              >
                <Link className="btn" to="/contact">
                  Contact
                </Link>
              </p>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <section className="schedule">
        <div className="container py-5">
          <div className="schedule-inner">
            <div className="row pb-5">
              <div className="col-lg-4 col-md-6 col-12 pb-3">
                <div className="single-schedule first">
                  <div className="inner">
                    <div className="icon">
                      <i className="icofont-lawyer-alt-2"></i>
                    </div>
                    <div className="single-content">
                      <span>Passionate Advocacy</span>
                      <h4>Fight for Justice</h4>
                      <p style={{ textAlign: "justify" }}>
                        Unyielding dedication to championing your rights and
                        seeking justice with passion and commitment.
                      </p>
                      <a href="/">
                        LEARN MORE<i className="icofont-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12  pb-3">
                <div className="single-schedule middle">
                  <div className="inner">
                    <div className="icon">
                      <i className="icofont-law-book"></i>
                    </div>
                    <div className="single-content">
                      <span>Optimized Approaches </span>
                      <h4>Best Case Strategy</h4>
                      <p style={{ textAlign: "justify" }}>
                        Tailored and strategic legal approaches designed to
                        optimize your chances of success in every case.
                      </p>
                      <a href="/">
                        LEARN MORE<i className="icofont-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-12 pb-3">
                <div className="single-schedule last">
                  <div className="inner">
                    <div className="icon">
                      <i className="icofont-lawyer"></i>
                    </div>
                    <div className="single-content">
                      <span>Trusted Expertise</span>
                      <h4>Experienced Attorney</h4>
                      <p style={{ textAlign: "justify" }}>
                        Trust our seasoned attorneys' wealth of expertise for
                        capable and knowledgeable legal representation representation.
                        
                      </p>
                      <a href="/">
                        LEARN MORE<i className="icofont-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="Feautes section"
        style={{ backgroundColor: "#FFF8D9" }}
      >
        <div className="container py-5">
          <div className="row py-5">
            <div className=" col-lg-4 col-md-12 col-12 col-lg-12 py-3">
              <div className="section-title">
                <h2 style={{ textAlign: "center" }}>
                  We Always Fight For Your Justice to Win
                </h2>
                <hr style={{ backgroundColor: "#D4AC2B" }} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="single-features">
                <div className="signle-icon">
                  <i className="icofont-bullseye"></i>
                </div>
                <h3>Our Mission</h3>
                <p>
                  Trust in a seasoned legal professional with a wealth of
                  expertise, ensuring your representation is in capable and
                  knowledgeable hands.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="single-features">
                <div className="signle-icon">
                  <i className="icofont-eye-open"></i>
                </div>
                <h3>Our Vision</h3>
                <p>
                  A future where legal advocacy fosters a fair and equitable
                  society, promoting lasting positive change.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6  col-12">
              <div className="single-features last">
                <div className="signle-icon">
                  <i className="icofont-unique-idea"></i>
                </div>
                <h3>Our Strategy</h3>
                <p>
                  Employing innovative and client-focused legal strategies to
                  navigate complexities and achieve optimal outcomes.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6  col-12">
              <div className="single-features last">
                <div className="signle-icon">
                  <i className="icofont-law-order"></i>
                </div>
                <h3>Our Value</h3>
                <p>
                  Upholding integrity, empathy, and excellence in every legal
                  endeavor, fostering trust and genuine client relationships.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="why-choose section"
        style={{ backgroundColor: "#f7e29e" }}
      >
        <div className="container py-5">
          <div className="row section">
            <div className="col-lg-12">
              <div className="section-title">
                <h2 style={{ textAlign: "center" }}>
                  We provide law-complient services for well-being.
                </h2>
                <hr style={{ backgroundColor: "#D4AC2B" }} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12">
              {/* <!-- Start Choose Left --> */}
              <div className="choose-left">
                <h3>Who We Are</h3>
                <p style={{ textAlign: "justify" }}>
                  LEGUM INN is a firm that provides end-to-end legal services.
                  We at LEGUM INN believe that it is necessary to provide
                  clients timely tangible results at optimum cost, be it
                  monetary, convenience or time. Our competent team of
                  associates comprise the young and dynamic, who are ably guided
                  by the experienced and are capable of providing flexible
                  solutions with an array of options best suited to our client’s
                  needs.
                </p>
                <p style={{ textAlign: "justify" }}>
                  LEGUM INN proposes relationship dynamics that go beyond
                  empathy and counseling, seeking to establish a responsible
                  partnership with its clients. Our underlying approach is to
                  comprehend each client’s perspective before proceeding to
                  identify the issues and apply a solution. We believe no two
                  issues are alike and each presents an opportunity to improve
                  the approach for solutions.
                </p>

                <p style={{ textAlign: "justify" }}>
                  Our one-stop full-legal-service approach allows our clients to
                  outsource legal matters which includes planning and interface
                  with any manner of legal issues. We at LEGUM INN are as
                  effective and convenient as an in-house legal department
                  offering more services albeit with cost optimization.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="choose-right">
                <div className="video-image"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog section" id="blog">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2 style={{ textAlign: "center" }}>Resources & Article</h2>
                <hr style={{ backgroundColor: "#D4AC2B" }} />
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-3 g-lg-3">
            <div className="col">
              <div className="single-news">
                <div className="news-head">
                  <img src={blog1} alt="#" />
                </div>
                <div className="news-body">
                  <div className="news-content">
                    <div className="date">22 Aug, 2020</div>
                    <h2>
                      <a href="/">Explore The Importance Of Having A Will</a>
                    </h2>
                    <p className="text">
                      A common excuse we’ve heard from those who have yet to
                      create their will is “my family will know what to do if I
                      die.”
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="single-news">
                <div className="news-head">
                  <img src={blog2} alt="#" />
                </div>
                <div className="news-body">
                  <div className="news-content">
                    <div className="date">15 Jul, 2020</div>
                    <h2>
                      <a href="blog-single.html">
                        The Impact of Social Media to the Society
                      </a>
                    </h2>
                    <p className="text">
                      When asked whether social media is a good or bad thing for
                      democracy in their country, a median of 57% across 19
                      countries.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              {/* <!-- Single Blog --> */}
              <div className="single-news">
                <div className="news-head">
                  <img src={blog3} alt="#" />
                </div>
                <div className="news-body">
                  <div className="news-content">
                    <div className="date">05 Jan, 2020</div>
                    <h2>
                      <a href="blog-single.html">
                        Estate Planing for Small Business & Articles
                      </a>
                    </h2>
                    <p className="text">
                      It becomes difficult for the rest of the employees to manage
                      and continue commercial activities in the proper order.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="appointment">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2 style={{ textAlign: "center" }}>
                  We are always there to help you.
                </h2>
                <h1 style={{ textAlign: "center" }}>Book An Appointment</h1>
                <hr style={{ backgroundColor: "#D4AC2B" }} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12 pb-5">
              <div className="appointment-image">
                <img src={contactimg} alt="#" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="contact-us-form">
                <h2>Contact With Us</h2>
                <p>
                  If you have any questions please fell free to contact with us.
                </p>
                {/* <!-- Form --> */}
                <form className="form">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          required=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          required=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="phone"
                          placeholder="Phone"
                          required=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="subject"
                          placeholder="Subject"
                          required=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          placeholder="Your Message"
                          required=""
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group login-btn">
                        <button
                          className="btn"
                          style={{ backgroundColor: "#D4AC2B" }}
                          type="submit"
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showModal && (
        <DisclaimerModal onAgree={handleAgree} onDisagree={handleDisagree} />
      )}
    </div>
  );
}

export default Home;
