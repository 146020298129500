import React, { Component } from "react";
import { Link } from "react-router-dom";
export class AllServices extends Component {
  render() {
    document.title = 'LEGUM INN-Services';
    return (
      <>
        <section className="all-service">
          <div className="bg_image_1">
            <div className="content">
              <h3>Services</h3>
            </div>
          </div>
          <div className="container py-5">
            <div className="row row-cols-1 row-cols-lg-4 row-cols-md-3 g-3 g-lg-3 ">
              <div className="col ">
                <div className="card shadow allservice-name ">
                  <div className="card-body">
                    <h5 className="card-title text-center">
                      <Link
                        className="btn"
                        to="/service"
                        style={{ fontWeight: "bold" }}
                      >
                        Real Estate & Infrastructure
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allservice-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "10px" }}>
                      <Link
                        className="btn"
                        to="/service"
                        style={{ fontWeight: "bold",textAlign:'center' }}
                      >
                        Employment & Industrial Relation
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allservice-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/service"
                        style={{ fontWeight: "bold" }}
                      >
                        Corporate Commercial Mergers & Acquisitions
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allservice-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/service"
                        style={{ fontWeight: "bold" }}
                      >
                        Information Technology /Cyber Law
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
           

              <div className="col">
                <div className="card shadow allservice-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/service"
                        style={{ fontWeight: "bold" }}
                      >
                        Banking and Finance Services
                        
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allservice-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/service"
                        style={{ fontWeight: "bold" }}
                      >
                        Entry & Investment Routes
                        
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allservice-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/service"
                        style={{ fontWeight: "bold" }}
                      >
                        Foreign Investment & Regulation
                        
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allservice-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/service"
                        style={{ fontWeight: "bold" }}
                      > Corporate Commercial Contracts
                        
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
          
          
              <div className="col ">
                <div className="card shadow allservice-name">
                  <div className="card-body">
                    <h5 className="card-title text-center">
                      <Link
                        className="btn"
                        to="/service"
                        style={{ fontWeight: "bold" }}
                      >
                        Private Equity and Venture Capital firms
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allservice-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/service"
                        style={{ fontWeight: "bold" }}
                      >
                       Arbitration /Alternative Dispute Resolution
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allservice-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/service"
                        style={{ fontWeight: "bold" }}
                      >
                       Asset Management & Financial Institutions
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allservice-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/service"
                        style={{ fontWeight: "bold" }}
                      >
                       Litigation Corporate Crimes
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
            
              <div className="col">
                <div className="card shadow allservice-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/service"
                        style={{ fontWeight: "bold" }}
                      >
                       Litigation Civil
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allservice-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/service"
                        style={{ fontWeight: "bold" }}
                      >
                       Business Advisory
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allservice-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px"}}>
                      <Link
                        className="btn"
                        to="/service"
                        style={{ fontWeight: "bold"}}
                      >
                        Education
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allservice-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/service"
                        style={{ fontWeight: "bold" }}
                      >
                        Retail & Franchising
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </section>
      </>
    );
  }
}

export default AllServices;
