import React, { Component } from "react";

export class Service extends Component {
  render() {
    document.title = 'LEGUM INN-Service';
    return (
      <div>
        <section className="about-us section">
          <div className="container py-5">
            <div className="inner">
              <h4 style={{textAlign:"center"}}>REAL ESTATE LITIGATION</h4>
              <p>
                We, represent owners, developers, secured lenders, loan
                participants, government bodies, bondholders, borrowers,
                landlords, tenants. We work closely with our clients in
                identification of their objectives and assist in formulating
                strategic and tactical objectives, and provide clear, proactive
                and practical advice. We represent our clients in disputes
                involving Labour and employment matters This multidisciplinary
                approach and broad experience translates into efficient and
                highly effective handling of clients’ real estate issues and
                disputes before, during, and after they result in litigation or
                other formal dispute resolution. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Debitis dignissimos adipisci,
                obcaecati unde odit quis voluptate porro facere repellat,
                numquam eius optio vero minima tempora voluptatibus, corrupti
                illo eligendi ut. Maiores harum recusandae fugiat incidunt,
                exercitationem alias quisquam, sit cumque animi esse laboriosam
                non ab accusantium. Unde aperiam tempore accusantium!
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Service;
