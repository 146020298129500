import React, { useState } from 'react';
import Modal from 'react-modal';
import '../styles.css';

const ImageGallery = ({ imageUrls }) => {

  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const isSmallScreen = window.innerWidth <=600;

  return (
    <div className='container-fluid'>
      <div className="gallery-container pb-4 py-5 d-flex flex-wrap justify-content-center">
        {imageUrls.map((imageUrl, index) => (
          <img
            key={index}
            src={imageUrl}
            alt=""
            onClick={() => openModal(imageUrl)}
            className="gallery-image"
          />
        ))}
      </div>

      <Modal
        isOpen={!!selectedImage}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
            },
            content: {
              position: 'relative',
              borderRadius: '5px',
              background:'#f8ecb8c7',
              outline: 'none',
              padding:0,
              marginBottom: isSmallScreen ? '60px' : '50px',
              marginRight: isSmallScreen ? '60px': 0,
            }
        
        }} 
      >
        <div >
          <img src={selectedImage} alt="Selected" className="selected-image" />
          <button onClick={closeModal} className="close-button">
          <i className="icofont-close"></i>
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ImageGallery;
