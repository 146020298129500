import React from 'react';
import { Link } from 'react-router-dom';

function AllSectors() {
  document.title = 'LEGUM INN-Sectors';
  return (
    <div>
      <section className="all-sector">
          <div className="bg_image_1">
            <div className="content">
              <h3>Sectors</h3>
            </div>
          </div>
          <div className="container py-5">
            <div className="row row-cols-1 row-cols-lg-4 row-cols-md-3 g-3 g-lg-3">
              <div className="col ">
                <div className="card shadow allsector-name">
                  <div className="card-body">
                    <h5 className="card-title text-center">
                      <Link
                        className="btn"
                        to="/sector"
                        style={{ fontWeight: "bold" }}
                      >
                        E-commerce
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allsector-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/sector"
                        style={{ fontWeight: "bold" }}
                      >
                       Insurance
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allsector-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/sector"
                        style={{ fontWeight: "bold" }}
                      >
                        Renewable Energy
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allsector-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/sector"
                        style={{ fontWeight: "bold" }}
                      >
                       Automobile
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allsector-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/sector"
                        style={{ fontWeight: "bold" }}
                      >
                        Education & Training
                        
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allsector-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/sector"
                        style={{ fontWeight: "bold" }}
                      >
                        IT & ITES
                        
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allsector-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/sector"
                        style={{ fontWeight: "bold" }}
                      >
                       Real Estate
                        
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allsector-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/sector"
                        style={{ fontWeight: "bold" }}
                      > Automobile Components
                        
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
            
          
              <div className="col ">
                <div className="card shadow allsector-name">
                  <div className="card-body">
                    <h5 className="card-title text-center">
                      <Link
                        className="btn"
                        to="/sector"
                        style={{ fontWeight: "bold" }}
                      >
                       Electrical Machinery
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allsector-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/sector"
                        style={{ fontWeight: "bold" }}
                      >
                       Manufacturing
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allsector-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/sector"
                        style={{ fontWeight: "bold" }}
                      >
                       Retail
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allsector-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/sector"
                        style={{ fontWeight: "bold" }}
                      >
                       Aviation
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
                        
              <div className="col">
                <div className="card shadow allsector-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/sector"
                        style={{ fontWeight: "bold" }}
                      >
                       Electronic Systems
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allsector-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/sector"
                        style={{ fontWeight: "bold" }}
                      >
                       Roads & Highways
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allsector-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/sector"
                        style={{ fontWeight: "bold" }}
                      >
                        Banking
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card shadow allsector-name">
                  <div className="card-body">
                    <h5 className="card-title text-center" style={{ textSize: "20px" }}>
                      <Link
                        className="btn"
                        to="/sector"
                        style={{ fontWeight: "bold" }}
                      >
                        Metal & Mining
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </section>
    </div>
  )
}

export default AllSectors
