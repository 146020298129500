import React from "react";

function Footer() {
  return (
    <div>
      <footer
        className="text-center text-lg-start text-dark"
        style={{ backgroundColor: "#FFF8D9" }}
      >
        <section
          className="d-flex justify-content-between p-4 text-white"
          style={{ backgroundColor: "#D4AC2B" }}
        >
          <div className="px-4">
            <span>Get connected with us on social networks:</span>
          </div>

          <div className="me-5">
            <a href="/" className="text-white mx-1">
              <i className="bx bxl-facebook"></i>
            </a>
            <a href="/" className="text-white">
              <i className="bx bxl-twitter"></i>
            </a>
            <a href="/" className="text-white mx-1">
              <i class="bx bxl-instagram"></i>
            </a>
          </div>
        </section>
        <section className="">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-12 col-lg-12 col-xl-4 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold">LEGUM INN</h6>
                <hr className="mb-4 mt-0 d-inline-block mx-auto" />
                <p style={{ textAlign: "justify" }}>
                  LEGUM INN is a firm that provides end-to-end legal services.
                  We at LEGUM INN believe that it is necessary to provide
                  clients timely tangible results at optimum cost, be it
                  monetary, convenience or time. Our competent team of
                  associates comprise the young and dynamic, who are ably guided
                  by the experienced and are capable of providing flexible
                  solutions with an array of options best suited to our client’s
                  needs.
                </p>
                <p>
                  <i className="bx bx-home "></i> <br />
                 
                  Saket Court Address: #329, Lawyers’ Chambers Block, Saket Court, New Delhi-110017
                 
                </p>
              </div>

              <div className="col-md-4 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold">Useful links</h6>
                <hr className="mb-4 mt-0 d-inline-block mx-auto" />
                <p>
                  <a href="/teams" className="text-dark">
                    Teams
                  </a>
                </p>
                <p>
                  <a href="/allsectors" className="text-dark">
                    Sectors
                  </a>
                </p>
                <p>
                  <a className="text-dark" href="/allservices">
                    Services
                  </a>
                </p>
                <p>
                  <a className="text-dark" href="/blogs">
                    Blogs
                  </a>
                </p>
                <p>
                  <a className="text-dark" href="/imagegallery">
                    Image Gallery
                  </a>
                </p>
                <p>
                  <a className="text-dark" href="/videogallery">
                    Video Gallery
                  </a>
                </p>
                <p>
                  <a className="text-dark" href="/aboutus">
                    About Us
                  </a>
                </p>
                <p>
                  <a className="text-dark" href="/contact">
                    Contact Us
                  </a>
                </p>
                <p>
                  <a className="text-dark" href="/career">
                    Career
                  </a>
                </p>
                
              </div>
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold">Contact</h6>
                <hr className="mb-4 mt-0 d-inline-block mx-auto" />
                <p>
                  <i className="bx bx-home "></i> <br />
                  10, First Floor, CSV New Friends Colony, Post Office Sriniwaspuri, South Delhi, New Delhi, Delhi, INDIA, 110065,<br/>
                  
                </p>
                <p>
                  <i className="bx bx-envelope "></i>
                  <br />leguminn@gmail.com
                </p>
                <p>
                  <i className="bx bx-phone "></i> <br />
                  +91 786 0786 427,
                  +917860000809
                  Landline No: +91-11-44725519
                </p>
                <p>
                  <i className="bx bx-time "></i> <br />
                  Mon-Sat: 10am – 7pm
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="text-center p-3" style={{ backgroundColor: "#f8efc7" }}>
          Copyright ©2024 LEGUM INN All Rights Reserved <br />
          <strong>Powered By</strong>{" "}
          <a
            style={{ textDecoration: "none", color: "black" }}
            href="https://abarissoftech.com/"
          >
            Abaris Softech
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
