import React,{useState} from "react";
import logo from "../image/Logo.png";
import { Link } from "react-router-dom";


function Navbar() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const closeNav = () => setIsNavCollapsed(true);
  return (
    <div>
      <nav
        className="navbar navbar-expand-lg navbar-light px-3 shadow fixed-top sticky"
        style={{ backgroundColor: "#FFF8D9"}}
      >
        <div className="container-fluid">
        <Link className="navbar-brand" to="/" style={{color:"#b8900cde"}}>
      <img src={logo} alt="" width="90" height="80" className="d-inline-block align-text-center"/>
       Legum Inn
    </Link>
          

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={!isNavCollapsed ? true : false}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${isNavCollapsed ? "" : "show"}`} id="navbarNav">
            <ul className="navbar-nav px-5">
              <li className="nav-item">
                <Link className="nav-link text-nowrap " onClick={closeNav} to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-nowrap" onClick={closeNav} to="/teams">
                  Teams
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-nowrap" onClick={closeNav} to="/corepractice">
                  Core Practice
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-nowrap" onClick={closeNav} to="/allsectors">
                  Sectors
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-nowrap" onClick={closeNav} to="/allservices">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-nowrap" onClick={closeNav} to="/blogs">
                  Media & Links
                </Link>
              </li>
              <li class="nav-item dropdown">
          <Link class="nav-link text-nowrap" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Gallery
          </Link>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link className="nav-link" onClick={closeNav} to="/imagegallery">Image Gallery</Link></li>
            <li><Link className="nav-link" onClick={closeNav} to="/videogallery">Video Gallery</Link></li>
          </ul>
        </li>
              <li className="nav-item">
                <Link className="nav-link text-nowrap" onClick={closeNav} to="/aboutus">
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-nowrap" onClick={closeNav} to="/contact">
                  Contact Us
                </Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link text-nowrap" onClick={closeNav} to="/career">
                  Career
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
