import React, { Component } from "react";

export class AboutUs extends Component {
  render() {
    document.title = 'LEGUM INN-AboutUs';
    return (
      <div>
        <section className="about">
      <div className="bg_image_1">
          <div className="content">
            <h3>About Us</h3>
          </div>
        </div>
        </section>
        <section className="about-us section">
          <div className="container py-5">
            <div className="inner">
              <p><em>
                A litigation firm headed by Supreme Court Advocate-on-Record
                LEGUM INN, offering a range of legal, transactional,
                regulatory and advisory services to a diverse clientele
                including in matters relating to constitutional law, commercial
                laws, personal laws, criminal law, arbitration, taxation, public
                policy, civil law, service law etc.
                </em></p><br/>
              <p>
                LEGUM INN Law Practices is a multidisciplinary law firm, with its
                advocates practicing before the Supreme Court of India and
                various High Courts, as well as before Tribunals. The firm,
                while engaged in providing advisory services on various aspects
                of civil and commercial laws to a diverse range of clients, also
                has a vast litigation practice, including but not limited to,
                civil appeals, arbitration, commercial litigation, service laws,
                taxation, criminal laws etc. The firm is headed by Supreme Court
                Advocate-on-Record LEGUM INN, a legal practitioner
                with over fifteen years of experience in the field of
                litigation, during which he has successfully dealt with a number
                of cases involving civil/contractual disputes, corporate
                matters, service matters, labour disputes, criminal appeals,
                among others, apart from also providing providing advisory
                services to clients, including State Governments/Departments,
                PSUs, Govt. Institutions, Companies etc. During his career,  .
                LEGUM INN has worked with Special Counsel of the Income Tax
                Department before the Authority for Advance Rulings (AAR) and
                has also worked with the topmost firms of the country namely
                Amarchand & Mangaldas & Suresh A. Shroff & Co., in their
                Corporate Tax Practice and Luthra & Luthra Law Offices in Delhi,
                in thier Tax practice. While the firm handled various private
                clients, including companies such as Hindustan Unilever Ltd.,
                and represented them in commercial litigations, it also benefits
                from the experience of LEGUM INN, who had also served as a
                Panel Counsel for the Union of India before the Hon’ble Supreme
                Court for about four years and conducted extensive drafting and
                litigation work. That apart,LEGUM INN has also served two
                consecutive terms as a Legal Consultant to the National Human
                Rights Commission, New Delhi and is presently the Standing
                Counsel of a Central University, whom he represents before the
                Delhi High Court & Suprmee Court of India. At the same time, the
                team of spirited and eloquent advocates at LEGUM INN, under the
                guidance of LEGUM INN, is also driven by the
                fundamental belief in seeing the legal profession as a means to
                help the last, lost and lonely and to that end engages in a
                comprehensive practice representing various clients, pro bono
                publico. In pursuance of the same objective, we have also filed
                several litigations including a PIL seeking accountability in
                the illegal employment of manual scavengers and seeking
                investigation into the wrongful deaths of manual scavengers; a
                petition seeking compliance of Supreme Court guidelines in
                appointment of qualified members to State Pollution Control
                Boards; another petition seeking establishment of community
                kitchens throughout the country.
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default AboutUs;
