import React from 'react'
import ImageGallery from './ImageGallery';
import '../styles.css';

function OurImageGallery() {
    const imageUrls = ['https://images.unsplash.com/photo-1593115057322-e94b77572f20?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8bGF3eWVyfGVufDB8fDB8fHww', 'https://images.unsplash.com/photo-1491336477066-31156b5e4f35?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGxhd3llcnxlbnwwfHwwfHx8MA%3D%3D', 'https://images.unsplash.com/photo-1562564055-71e051d33c19?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGxhd3llcnxlbnwwfHwwfHx8MA%3D%3D', 'https://images.unsplash.com/photo-1447023029226-ef8f6b52e3ea?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fGxhd3llcnxlbnwwfHwwfHx8MA%3D%3D'];
  return (
    <div>
      <section className="image-gallery">
        <div className="bg_image_1">
          <div className="content">
            <h3>Image Gallery</h3>
          </div>
        </div>
        <div className='py-5'>
      <ImageGallery imageUrls={imageUrls} />
    </div>
      </section>

    </div>
  )
}

export default OurImageGallery
