import React from "react";
import logo from '../image/Logo.png';

const DisclaimerModal = ({ onAgree, onDisagree }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <h2 className="logo"><img src={logo} alt="logo" />Legum Inn</h2>
        <div class="container">
          <div class="row ">
            <div class="text-content">
              <h2>Disclaimer</h2>
              <p>
                The rules of the Bar Council of India prohibit law firms from
                soliciting work or advertising in any manner. By clicking on 'I
                AGREE', the user acknowledges that:
              </p>
              <p>
                The user wishes to gain more information about LEGUM INN, its practice areas and its attorneys, for his/her own
                information and use.
              </p>
              <p>
                The information is made available/provided to the user only on
                his/her specific request and any information obtained or
                material downloaded from this website is completely at the
                user's volition and any transmission, receipt or use of this
                site is not intended to, and will not, create any lawyer-client
                relationship.
              </p>
              <p>
                None of the information contained on the website is in the
                nature of a legal opinion or otherwise amounts to any legal
                advice.
              </p>
              <p>
                LEGUM INN is not liable for any consequence of any
                action taken by the user relying on material/information
                provided under this website. In cases where the user has any
                legal issues, he/she in all cases must seek independent legal
                advice.
              </p>
            </div>
          </div>
        </div>
          <div className="container py-2">
          <button
            class="btn btn-primary me-2 "
            type="button"
            onClick={onAgree}
          >
            Agree
          </button>
          <button class="btn btn-danger " type="button" onClick={onDisagree}>
            Disagree
          </button>
          </div>
              </div>
    </div>
  );
};

export default DisclaimerModal;
