import React, { Component } from "react";
import img from "../image/pf2.jpg";

export class CorePractice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeParagraph: "paragraph1",
    };
  }

  showParagraph = (paragraphId) => {
    this.setState({ activeParagraph: paragraphId });
  };
  
  render() {
    const { activeParagraph } = this.state;
    document.title = 'LEGUM INN-CorePractice';
    return (
      <div>
        <section className="corepractice">
      <div className="bg_image_1">
          <div className="content">
            <h3>Core Practice</h3>
          </div>
        </div>
        </section>
        <div className="container-fluid">
          <div
            className="row align-items-center px-5 py-5"
            style={{ backgroundColor: "#FFF8D9", overflow:"hidden"}}
          >
            <div className=" col-lg-8 col-md-12 col-sm-8 col-12 ">
              <p style={{textAlign:'justify'}}>
                <em>
                  With its advocates practicing before the Supreme Court of
                  India as well as High Courts and Tribunals,LEGUM INN offers a
                  wide-range of legal, transactional, regulatory and advisory
                  services to a diverse clientele.
                </em>
              </p>
              {/* <div className="content-box"> */}
              <div
                className="btn-group"
                role="group"
                aria-label="Basic outlined example"
              >
                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.showParagraph("paragraph1")}>
                Litigation
                </button>
                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.showParagraph("paragraph2")}>
                Advisory
                </button>
                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.showParagraph("paragraph3")}>
                Arbitration
                </button>
                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.showParagraph("paragraph4")}>
                Pro Bono Practice
                </button>
              </div>
              
              <p
                style={{
                  display: activeParagraph === "paragraph1" ? "block" : "none",
                  textAlign:'justify',
                }}
              >
                LEGUM INN deals with a number of cases representing a diverse
                clientele before different courts and tribunals, including
                matters pertaining to constitutional law, commercial laws,
                taxation, family laws, personal laws, criminal prosecutions &
                appeals, civil disputes, property law, service law, amongst
                others.
              </p>
              <p
                style={{
                  display: activeParagraph === "paragraph2" ? "block" : "none",
                  textAlign:'justify',
                }}
              >
                The firm also provides legal assistance and advisory to clients
                including companies towards transactional, regulatory, due
                diligence and statutory compliances, etc.
              </p>
              <p
                style={{
                  display: activeParagraph === "paragraph3" ? "block" : "none",
                  textAlign:'justify',
                }}
              >
                LEGUM INN is engaged and has previously been engaged in
                representation of clients in arbitrations pertaining to
                commercial transactions and contractual disputes.
              </p>
              <p
                style={{
                  display: activeParagraph === "paragraph4" ? "block" : "none",
                  textAlign:'justify',
                }}
              >
                Driven by the fundamental belief in seeing the legal profession
                as being the means to serve the last, lost and the lonely, the
                team at LEGUM INN engages in a substantial pro bono practice,
                providing appropriate legal representation and assistance to
                underprivileged clients, including but not limited to, a number
                of individuals effected by the Foreigners' Act, 1946 and the
                NRC, Assam, among others. As part of the same belief, we have
                also instituted several litigations with the aim of advancing
                societal change by way of the legal practice, raising
                substantial social concerns related to environment, human
                rights, education etc. from time to time.
              </p>
              {/* </div> */}
            </div>
            <div className="col">
              <div className="column image-column">
                <img src={img} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="core-practice section">
          <div className="container">
            <div className="column text-column"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default CorePractice;
