import React from "react";
import img from "../image/pf4.jpg";

function Teams() {
  document.title = 'LEGUM INN-Teams';
  return (
    <div style={{backgroundColor: "#f8ecb8" }}>
      <section className="team">
      <div className="bg_image_1">
          <div className="content">
            <h3> Our Advocate Team</h3>
          </div>
        </div>
        </section>
      <p className="px-5 py-5" style={{textAlign:'justify',marginLeft:"20px"}}>
        We aspire to build a work culture that puts our people first. Nurturing
        them along their career-path, LEGUM INN is committed to help our teams fulfil
        their career goals. Our ethos of equality, meritocracy, constant
        learning and focus on work-life balance ensures that our people realise
        their full potential.
      </p>
      <div className="container py-3">
        <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-3 g-lg-3">
          <div className="col">
            <div className="card shadow" >
              <img src={img} className="card-img-top" alt="..." />
              <div className="card-body" style={{backgroundColor: "#f8ecb8" }}>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card shadow" >
              <img src={img} className="card-img-top" alt="..." />
              <div className="card-body" style={{backgroundColor: "#f8ecb8" }}>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card shadow" >
              <img src={img} className="card-img-top" alt="..." />
              <div className="card-body" style={{backgroundColor: "#f8ecb8" }}>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
        
          <div className="col">
            <div className="card shadow" >
              <img src={img} className="card-img-top" alt="..." />
              <div className="card-body" style={{backgroundColor: "#f8ecb8" }}>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card shadow" >
              <img src={img} className="card-img-top" alt="..." />
              <div className="card-body" style={{backgroundColor: "#f8ecb8" }}>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card shadow" >
              <img src={img} className="card-img-top" alt="..." />
              <div className="card-body" style={{backgroundColor: "#f8ecb8" }}>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
  );
}

export default Teams;
